import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const [weight, setWeight] = useState("");
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = () => {
    if (!weight) {
      alert("Please select a weight option.");
      return;
    }
    addToCart(product.id, quantities, weight);
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
            <div className="goBackToShop">
              <a href="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</a>
            </div>
          </div>
          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>{product.name}</h3>
              </div>
            </div>
            <div className="summary-container">
              <ul>
                {product.description.map((des, index) => (
                  <li key={index}>
                    <div className="woocommerce-product-details__short-description">
                      <p>{des}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="template_content">
                <div>
                  <h4 className="stock-in">{product.price}</h4>
                </div>
                <div className="field configurable required">
                  <div className="control">
                    <select
                      name="super_attribute[144]"
                      data-selector="super_attribute[144]"
                      data-validate="{required:true}"
                      id="attribute144"
                      className="super-attribute-select"
                      aria-required="true"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    >
                      <option value="">Choose an Option...</option>
                      {product.weight.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="takeQuantity">
                  <input
                    type="number"
                    value={quantities}
                    min="1"
                    onChange={(e) =>
                      setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                    }
                    className="quantity-input"
                  />
                </div>
                <div className="btnDiv">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    Add to Cart
                  </button>
                </div>
              </div>

              {/* <div className="botFee">
                <span>
                  Delivery on: <strong>Sunday 4 August</strong>
                </span>
                <br />
                <span>Choose Premium or Express shipping option</span>
                <br />
                <span>
                  <strong>Order within 19hrs 41min</strong>
                </span>
              </div>

              <div class="delivery-section">
                <h3>Delivery Information</h3>
                <p>
                  <span class="delivery-name">
                    Express (Next Day: Order Before 2pm)
                  </span>{" "}
                  £6.00
                </p>
                <p>
                  <span class="delivery-name">Premium (1-2 Working Days)</span>{" "}
                  £5.00
                </p>
                <p>
                  <span class="delivery-name">Standard (2-4 Working Days)</span>{" "}
                  £4.00
                </p>
                <p>
                  <span class="delivery-name">
                    Free Delivery - Orders Over £50+
                  </span>{" "}
                  £0.00
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
