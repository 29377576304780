import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img alt="" src="/fruit/main1.jpg" />
          <div className="rslides-comment">
            <p className="welcome-strong">
              Welcome to
              <strong>British Frozen Fruits</strong>
            </p>
            <p className="shop-a-link">
              <a href="/shop">Shop Now</a>
            </p>
          </div>
        </div>
        <div className="hp-grid">
          <div className="grid-left">
            <div className="grid-bf">
              <img alt="" src="/fruit/mid1.jpg" />
              <span className="grid-span">
                British and <br />
                <span className="grid-span-span">Herefordshire Fruit </span>
                <span className="read-more">&gt;</span>
              </span>
            </div>
            <div className="grid-bf">
              <img alt="" src="/fruit/mid2.jpg" />
              <span className="grid-span">
                <span className="grid-span-span">International </span>
                <span className="read-more">&gt;</span>
              </span>
            </div>
            <div className="grid-bf">
              <img alt="" src="/fruit/mid3.jpg" />
              <span className="grid-span">
                <span className="grid-span-span">All Fruit </span>
                <span className="read-more">&gt;</span>
              </span>
            </div>
            <div className="grid-bf">
              <img alt="" src="/fruit/mid4.png" />
            </div>
          </div>
          <div className="grid-right">
            <div className="grid-rf">
              <img alt="" src="/fruit/mid5.jpg" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
