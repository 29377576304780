import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../css/shop.css";
import productData from "../Datas/productData";

function Shop() {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  // Calculate the index of the first and last product on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // Slice the data array to get the products for the current page
  const currentProducts = productData.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Calculate total pages
  const totalPages = Math.ceil(productData.length / productsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>Buy Frozen Fruits Online</h1>
            <div className="product-grid">
              {currentProducts.map((brand) => (
                <div className="product-category" key={brand.id}>
                  <div className="image-container">
                    <Link to={`/shopDetails/${brand.id}`}>
                      <img alt="" src={brand.images[0]} />
                    </Link>
                    <h2>{brand.name}</h2>
                    <h4>
                      From&nbsp;<strong>{brand.price}</strong>
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Pagination */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
