import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faClock, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const {
    cartItems,
    removeFromCart,
    updateQuantity,
    shippingCost,
    setShippingCost,
  } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity; // 计算总价
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="cart-header">
            <FontAwesomeIcon icon={faList} style={{ color: "#1e85be" }} />
            <p>
              Some items in your basket are discounted for members. Log in to
              claim them!
            </p>
          </div>
          <div className="basket-title">
            <h1>Shopping Basket</h1>
            <div className="cart-status-message">
              <p>Current Order Dispatch Time:</p>
              <span>
                <FontAwesomeIcon icon={faClock} />
                Shipped Within 1 Hour
              </span>
            </div>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  paddingRight: "30px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                }}
                              >
                                {product.name}
                                <br />
                                <span>
                                  <strong>Size:</strong>&nbsp;
                                  {item.weight}
                                </span>
                              </div>
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              {product.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) => handleInputChange(item.id, e)}
                                min="0"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "middle" }}
                            >
                              £
                              {calculateItemTotal(
                                item.id,
                                item.quantity
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "middle" }}
                            >
                              <a
                                onClick={() =>
                                  removeFromCart(item.id, item.weight)
                                }
                                style={{
                                  cursor: "context-menu",
                                  textDecoration: "underline",
                                  fontFamily: "Libre Baskerville",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <h2 className="basketDiv">Summary</h2>

                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">Subtotal</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>

                        <tr className="sumTr">
                          <th className="subtotalTh">Total(inc VAT)</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh1">Total</th>
                          <th className="subtotalTxt1">
                            £{(shippingCost + calculateTotal()).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <button className="checkout-button" onClick={toCheckOut}>
                      Proceed to checkout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
