const productData = [
  {
    id: 1,
    name: "Frozen Blackcurrants",
    description: [
      "Available in either 3kg bags or 12kg boxes, our delicious Blackcurrants are perfect for making jam, ice cream, cordial, or any other of your favourite desserts.",
      "With their strong, fruity and slightly tart flavour this 'superfruit' is a great source of Vitamin C and Fibre, which makes them a great choice for getting these important nutrients into your daily diet. Find out more about the benefits of blackcurrants here.",
      "Grown on our farm in Herefordshire, where the climate and soil conditions allow our blackcurrants to thrive, the fruit is Individually Quick Frozen and hand-selected before making its way to your doorstep.",
    ],
    price: "£16.50",
    weight: ["3kg", "12kg"],
    images: ["/images/10001.jpeg", "/images/10001a.jpg", "/images/10001b.jpg"],
  },
  {
    id: 2,
    name: "Premium Frozen Raspberries",
    description: [
      "Available in 2kg bags or 10kg boxes, these frozen raspberries are our very best quality - a taste of the British summer throughout the year. They are perfect for adding to cakes, as porridge toppings, in smoothies or even in a glass of prosecco or beverage of your choice! ",
      "They are filled to the brim with natural goodness and have the most delightful taste. A fruity low calorie snack that is high in magnesium and vitamin C.",
      "Grown and picked in Herefordshire. These raspberries have been hand-picked at the height of the season at optimum ripeness then individually quick frozen to secure their flavour. ",
      "**NOTE: Due to the fragile nature of the product some berries may become crumbled in transit.",
    ],
    price: "£16.50",
    weight: ["3kg", "12kg"],
    images: ["/images/10002.jpg", "/images/100021.jpg"],
  },
  {
    id: 3,
    name: "Organic Frozen Blackcurrants",
    description: [
      "Available in either 3kg bags or 12kg boxes, our delicious Organic Blackcurrants are grown on our farm in Herefordshire, where the climate and soil conditions allow our fruit to thrive. They're perfect for making blackcurrant jam, blackcurrant ice cream, fruit cordial, or any other of your favourite fruit desserts.",
      "With their strong, fruity and slightly tart flavour this 'superfruit' is a great source of Vitamin C and Fibre*, which makes them a great choice for getting these important nutrients into your daily diet. Find out more about the benefits of blackcurrants here.",
      "These Organic Frozen Blackcurrants are accredited organic by the Soil Association, and we harvest this organic fruit at their optimum ripeness during the peak of their short season - so you have the highest quality, hand-selected fruit.",
    ],
    price: "£26.00",
    weight: ["3kg", "12kg"],
    images: [
      "/images/10002.jpeg",
      "/images/100031.jpg",
      "/images/100032.jpg",
      "/images/100033.jpg",
    ],
  },
  {
    id: 4,
    name: "Frozen Strawberries",
    description: [
      "Available in 2.5kg bags or 10kg boxes, our Frozen Strawberries bring the classic taste of British Summer with a nostalgic aroma all year round! They are irresistibly sweet and rich in natural flavour. Perfect for baking, fruit smoothies, milkshakes or even in a strawberry daiquiri!! ",
      "This versatile, family-favourite fruit is high in Vitamin C*, supports the immune system and is a great source of folic acid.",
      "Grown and hand-picked on our Herefordshire farm our Strawberries are picked at their optimum ripeness before being Individually Quick Frozen and packed so you can enjoy fresh, British fruit any time of the year.",
    ],
    price: "£18.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/10004.jpeg", "/images/100041.jpg", "/images/100042.jpg"],
  },
  {
    id: 5,
    name: "Frozen Rhubarb Chunks",
    description: [
      "Available in 2.5kg bags or 10kg boxes, these British grown rhubarb chunks are the ideal freezer staple to make crumbles, cordial or chutney. They have a unique tart, fruity flavour which pairs well with spices in sweet dishes or in a savoury pork dish.",
      "This UK grown Rhubarb has been harvested at its peak, cut into easy to use chunks and then quickly frozen for the most delicious flavour all year round.",
      "You can see some frequently asked questions about rhubarb here.",
    ],
    price: "£20.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/10005.jpeg", "/images/100051.jpg"],
  },
  {
    id: 6,
    name: "Organic Frozen Blackberries",
    description: [
      "Available in 2kg bags, these juicy Organic British Blackberries are our highest quality Blackberries which are hand-picked when their flavour is at its best. They are perfect for all of your baking and cooking needs, or just for year-round snacking. You can also enjoy in pies, crumbles and a natural partner for rich or game meat - venison, pheasant or partridge.",
      "Blackberries are high in fibre, vitamin C and lots of other healthy attributes.",
      "Grown and picked on our Herefordshire farm our Blackberries are accredited organic by the Soil Association, quickly frozen and packed so you can enjoy fresh, British fruit any time of the year.",
    ],
    price: "£20.00",
    weight: ["2.5kg"],
    images: ["/images/10006.jpeg", "/images/100061.jpg"],
  },
  {
    id: 7,
    name: "Frozen Blueberries",
    description: [
      "Available in 2kg bags, our Blueberries have a delicious sweet flavour. Often labelled as a ‘superfood’, they are packed with Vitamins K, C & B6, fibre and manganese as well as one of the highest levels of antioxidants.",
      "Perfect for recipes like blueberry muffins, blueberry jam, or even just for adding to your daily breakfast; they make a great, and healthy, addition to any freezer.",
      "These frozen blueberries are hand-picked at their optimum ripeness before they are frozen, so you can enjoy the fruit all year round.",
    ],
    price: "£18.00",
    weight: ["2kg"],
    images: ["/images/10007.jpeg", "/images/100071.jpg", "/images/100072.jpg"],
  },
  {
    id: 8,
    name: "Frozen Gooseberries",
    description: [
      "Available in 3kg bags or 12kg boxes, our British frozen gooseberries have a tart, grape-like flavour and are perfect for a variety of recipes such as a gooseberry cake, fool, or jam and chutney. They also work great with Elderflower, or oily fish.",
      "Gooseberries are picked fresh at the height of the season and frozen so you can enjoy them all year round. ",
      "You can see some frequently asked questions about gooseberries here.",
    ],
    price: "£18.00",
    weight: ["3kg", "12kg"],
    images: ["/images/10008.jpeg"],
  },
  {
    id: 9,
    name: "Frozen Raspberries",
    description: [
      "Available in either 2.5kg bags or 12kg boxes, this selection of frozen raspberries is a mix of raspberry crumb, broken raspberries and a small amount of whole fruit. This makes them perfect for making jam. Other uses include adding to porridge, smoothies, or any raspberry recipes for a berry kick! As these are a by-product from picking our Premium Raspberries, it is also great for the environment by minimising food waste in a sustainable and delicious way.",
      "With their light, crisp and sweet flavour these raspberries are a great source of Fibre with each 100g serving containing 2.5g* making them an excellent addition to any freezer.",
      "Grown and picked in Herefordshire. These raspberries are quickly frozen and packed so you can enjoy fresh, British fruit any time of the year. ",
    ],
    price: "£18.00",
    weight: ["2.5kg", "12kg"],
    images: ["/images/10009.jpeg", "/images/100091.jpg"],
  },
  {
    id: 10,
    name: "Frozen Organic Seville Oranges",
    description: [
      "Available in 2.5kg bags or 10kg boxes, our Frozen Organic Seville Oranges are sourced from famous traditional orchards renowned for growing the best bitter oranges.",
      "We recommend you use this fantastic orange for making Seville Orange Marmalade, or for using in a Seville Orange gin.",
      "Organic, unwaxed, and purchased directly from Seville, Spain during their peak season, this organic fruit has the optimum pectin levels for making excellent marmalade. They are quickly frozen and packed ready for you to enjoy whenever you please - no matter the season.",
    ],
    price: "£19.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/100010.jpeg", "/images/1000101.jpg"],
  },
  {
    id: 11,
    name: "Frozen Plum Halves",
    description: [
      "Available in 2kg bags or 10kg boxes, these Plum Halves are a great choice for making jam, crumbles, cakes, or even poached with some mild spices. They look fantastic in an upside down plum cake!",
      "Our Plum Halves have been halved and destoned* then quickly frozen so you can use them at any time of the year for a fresh, quality taste.  ",
      "*NOTE: we cannot guarantee complete removal of stone and stone fragments.",
    ],
    price: "£14.00",
    weight: ["2kg", "10kg"],
    images: ["/images/100011.jpg"],
  },
  {
    id: 12,
    name: "Frozen Diced Apricot",
    description: [
      "Available in 10kg boxes, our Frozen Diced Apricots are a great addition to your freezer, with their sweet but tart flavour. They are perfect for creating a tasty apricot smoothie, making apricot jam, or in any other desserts.",
      "We've diced our apricots and frozen them quickly to ensure they are of the highest quality for you to enjoy at any time of the year.",
    ],
    price: "£40.00",
    weight: ["10kg"],
    images: ["/images/100012.jpeg", "/images/1000121.jpg"],
  },
  {
    id: 13,
    name: "Frozen Bramley Apple Slices",
    description: [
      "Available in 2.5kg bags or 10kg boxes, these UK grown British Bramley Apple Slices are the perfect cooking apple due to their sourness and acidity. Use them to make a variety of recipes such as apple crumble, chutney or apple sauce to accompany your Sunday roast!",
      "They also pair perfectly with our organic blackberries to make a fruity crumble.",
      "The Bramley Apple Slices have been peeled, sliced and dipped in an antioxidant to keep them from browning. They are then quickly frozen so they can be easily used whenever you need them.",
    ],
    price: "£20.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/100013.jpeg"],
  },

  {
    id: 15,
    name: "Frozen Plum Halves",
    description: [
      "Available in 2kg bags or 10kg boxes, these British Plum Halves are a great choice for making jam, crumbles, cakes, or even poached with some mild spices. They look fantastic in an upside down plum cake!",
      "Our UK grown Plum Halves have been halved and destoned* then quickly frozen so you can use them at any time of the year for a fresh, quality taste. ",
      "*NOTE: we cannot guarantee complete removal of stone and stone fragments.",
    ],
    price: "£21.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/10015.jpg"],
  },
  {
    id: 16,
    name: "Frozen Redcurrants",
    description: [
      "Available in either 3kg bags or 12kg boxes, our delicious redcurrants have a fruity but tart flavour. They are perfect for making redcurrant jelly, redcurrant sauce or other redcurrant recipes. They also make a delicious addition to a sauce or jus to enjoy with dark meats - lamb, pheasant, venison etc.",
      "Redcurrants are often underated as they have so many positive health benefits. They aid in helping to fight infections, help improve digestive health and are great for your hair and skin.",
      "Grown on our farm in Herefordshire and harvested in the height of the season, these redcurrants are Individually Quick Frozen, strigged and hand-selected before making their way to your doorstep.",
    ],
    price: "£21.00",
    weight: ["3kg", "14kg"],
    images: ["/images/ccc.jpeg"],
  },
  {
    id: 17,
    name: "Frozen Sweet Eating Fruit",
    description: [
      "Our 2kg bag of British Sweet Eating Fruit is specifically chosen by hand - a mix of delicious Blueberries, sweet variety Blackberries, and our Premium Strawberries, to provide you with the perfect selection of sweet eating fruit.",
      "We recommend you use them to top cereal, porridge, yoghurt, ice-cream, or just for snacking, as they are ready to eat.",
      "We grow this mix of sweet fruit for their flavour on our Herefordshire farm, where they are picked at their optimum ripeness before being quickly frozen so your British fruit is high-quality all year round.",
    ],
    price: "£16.50",
    weight: ["2kg"],
    images: ["/images/bbb.jpg"],
  },
  {
    id: 18,
    name: "Mango Chunks",
    description: [
      "Available in 2.5kg bags or 10kg boxes, our Mango is an incredibly popular tropical fruit. Known for its fragrant sweetness, succulent texture and bright colour, it's the perfect fruit for a tasty smoothie, added into salads or paired with your favourite fish.",
      "Our frozen mango chunks are cut into easy to use pieces, so it's ready whenever you need it.",
      "Why not try our Super Quick Tropical Frozen Fruit Yoghurt.",
    ],
    price: "£20.00",
    weight: ["2.5kg", "10kg"],
    images: ["/images/aaa.jpeg"],
  },
];

export default productData;
